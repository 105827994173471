import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { NgxImageCompressService } from 'ngx-image-compress-legacy';
// import { NgxImageCompressService } from 'ngx-image-compress';
// import { NgxPicaModule } from 'ngx-pica';
import { NgCalendarModule  } from 'ionic2-calendar';
const modules: QuillModule = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], //toggled buttons
    ['blockquote'], //, 'code-block'
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean'],                                         // remove formatting button

    // ['link', 'image', 'video']                         // link and image, video
  ]
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, ChartsModule, HttpClientModule, QuillModule.forRoot(
    {
      modules,
      placeholder: 'Notes...',
    }
  ),
    IonicStorageModule.forRoot(), NgxIonicImageViewerModule,
    NgCalendarModule
    // NgxPicaModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    File,
    WebView,
    FilePath,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
