import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('./pages/expense/expense.module').then(m => m.ExpensePageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then(m => m.WalletPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./pages/transfer/transfer.module').then(m => m.TransferPageModule)
  },

  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then(m => m.ActivityPageModule)
  },
  {
    path: 'add-contact',
    loadChildren: () => import('./pages/add-contact/add-contact.module').then(m => m.AddContactPageModule)
  },
  {
    path: 'add-budget',
    loadChildren: () => import('./pages/add-budget/add-budget.module').then(m => m.AddBudgetPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'assigned-task',
    loadChildren: () => import('./pages/assigned-task/assigned-task.module').then(m => m.AssignedTaskPageModule)
  },
  {
    path: 'activate',
    loadChildren: () => import('./pages/activateaccount/activateaccount.module').then(m => m.ActivateAccountPageModule)
  },
  {
    path: 'approval-view',
    loadChildren: () => import('./pages/approval-view/approval-view.module').then(m => m.ApprovalViewPageModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('./pages/notes/notes.module').then(m => m.NotesPageModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsPageModule)
  },
  {
    path: 'group',
    loadChildren: () => import('./pages/group/group.module').then(m => m.GroupPageModule)
  },
  {
    path: 'popover-notify',
    loadChildren: () => import('./pages/popover-notify/popover-notify.module').then(m => m.PopoverNotifyPageModule)
  },
  {
    path: 'notes-create',
    loadChildren: () => import('./pages/notes-create/notes-create.module').then(m => m.NotesCreatePageModule)
  },
  {
    path: 'closed-report',
    loadChildren: () => import('./pages/closed-report/closed-report.module').then(m => m.ClosedReportPageModule)
  },
  {
    path: 'group-report',
    loadChildren: () => import('./pages/group-report/group-report.module').then(m => m.GroupReportPageModule)
  },

  {
    path: 'user-report',
    loadChildren: () => import('./pages/user-report/user-report.module').then(m => m.UserReportPageModule)
  },
];
@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash : true })
    // RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
